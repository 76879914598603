<template>
  <div class="hf-wrapper hf-select-wrapper">
    <label v-if="label">{{ label }}</label>
    <div class="description" v-if="description">{{ description }}</div>

    <aSelect
      class="hf-select"
      v-if="!loading"
      :class="fieldClass"
      :placeholder="placeholder || 'Selecione'"
      :optionFilterProp="optionFilterProp"
      :allowClear="allowClear"
      :showSearch="showSearch"
      :loading="loading"
      v-model="fieldValue"
      @change="onChange"
      @search="onSearch"
      @select="onSelect"
    >
      <aSelectOption
        v-for="(item, index) of list"
        :key="index"
        :value="item.value"
        :txt="item.label"
      >
        {{ item.label }}
      </aSelectOption>
    </aSelect>
  </div>
</template>

<script>
export default {
  name: "HayaSelectField",
  inheritAttrs: false,
  props: {
    label: String,
    description: String,
    fieldKey: String,
    placeholder: String,
    open: Boolean,
    optionFilterProp: String,
    requiredMessage: String,
    fieldClass: String,
    initialValue: String,
    allowClear: Boolean,
    showSearch: Boolean,
    required: Boolean,
    list: Array,
    readonly: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      fieldValue: undefined,
    };
  },
  beforeMount() {},
  // watch: {
  //   fieldValue(aa, bb) {
  //     console.log(aa, bb);
  //   },
  // },
  methods: {
    onChange(e) {
      this.$emit("onChangeSelectField", e);
    },
    onSearch(e) {
      this.$emit("onSearchSelectField", e);
    },
    onSelect(e) {
      this.$emit("onSelectSelectField", e);
    },
  },
};
</script>

<style lang="sass">

.hf-select-wrapper
  .ant-form-explain
    font-size: 9px !important
    top: -4px
    position: relative
  .ant-select-selection
    border-width: 0 0 2px 0
    border-radius: 0
</style>

<style lang="sass" scoped>
.hf-select
  width: 100%
.hf-wrapper
  label
    font-size: 10px
    font-weight: 500
    color: #333
    line-height: 1
    text-overflow: ellipsis
    width: 100%
    white-space: nowrap
    margin-top: -12px
    margin-bottom: 2px
    display: block

  .description
    font-size: 9px
    line-height: 1.2
    margin: 8px  0 3px
    font-weight: 500
    border-left: 2px solid #ddd
    padding-left: 7px
</style>
